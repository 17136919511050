

.alert--box {
    @extend .alert;

    position: relative;
    padding: $indent-s $indent-s $indent-s $indent-xl;
    border: 1px solid $color-text;

    &.full-width {
        width: 100%;
        max-width: unset;
    }

    &:before {
        content: "\e08b";
        position: absolute;
        top: $indent-s;
        left: $indent-s;
        line-height: 24px; //PX for IE..
        width: $font-size-icon;
        height: $font-size-icon;
        color: $color-text;
        font-family: $font-icons;
        font-size: $font-size-icon;
    }

    .alert--box__no_list {
        list-style: none;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    @media (max-width: $screen-xs) {
        padding: $indent-xs $indent-base $indent-xs $indent-xl;

        &:before {
            top: $indent-xs;
        }
    }
}
