

$card-simple-indent: 10rem;
$card-simple-item-max-width: 33.5rem;

.card--simple__intro {
    @extend .card__intro;

    height: $line-height-m * 2;
    max-width: 36rem;
    margin-top: $header-height + $indent-l;
    color: $color-default;

    @include mi-flexbox();
    @include mi-flex-direction(column);
    @include mi-flex-justify-content(flex-end);
}

.card--simple {
    @extend .card;

    padding: $indent-hor $indent-xxl $indent-l;
    background: var(--backdrop-desktop) no-repeat top left;
    background-size: cover;
    color: $color-default;
    min-height: $card-height;
    height: unset;

    @include mi-flexbox();
    @include mi-flex-direction(column);

    &:before,
    &:after {
        display: none;
    }

    .card--simple {
        height: auto;
        margin: 0;
        padding: 0;
        background: transparent;

        @include mi-no-shadow;
        @include mi-flex(1 1 100%);
    }

    h1 {
        margin: $indent-xs 0 $indent-m;
    }

    .card--simple__image {
        display: block;
        width: auto;
        height: 13.4rem;
        margin: -$indent-xs auto $indent-m;

        @include mi-align-self(center);
    }

    p {
        .button--icon {
            margin: 0 $indent-xs;
            vertical-align: middle;
        }
    }

    .form {
        width: $card-simple-item-max-width;
        margin: auto;
        color: $color-default;

        .form__label {
            left: 0;
        }

        .form__item {
            padding-left: 0;

            i {
                left: -3rem;
            }
        }

        .form__input--text {
            color: $color-default;
            border-bottom-color: $color-default;
        }
    }

    .form--bg {
        background-image: none;
        box-shadow: none;
        margin: 0 auto;
        max-width: 370px;

        &.form--bg--full-width {
            max-width: unset;
            width: 100%;
        }
    }

    .card--simple__buttons {
        margin: auto 0 0;
    }

    .button--cta {
        padding-left: $indent-xxl;
        padding-right: $indent-xxl;
    }

    .button--link {
        margin: 0;
    }

    .alert--box--error {
        display: block;
        max-width: $card-simple-item-max-width;
        width: auto;
        margin: 0 auto $indent-m;

        @include mi-align-self(center);
    }

    .text-center {
        @include mi-flex(1 1 auto);
        @include mi-flexbox();
        @include mi-flex-direction(column);
        @include mi-flex-align-content(center);

        .button--cta {
            width: 32rem;
            margin: auto auto $indent-m;
            padding-left: $indent-xxl;
            padding-right: $indent-xxl;
        }
    }

    @media (max-width: $screen-md) {
        h1 {
            margin: $indent-xs 0 $indent-m;
        }

        .card--simple__image {
            height: 13rem;
            margin-top: $indent-xs;
        }

        .alert--box--error {
            margin: 0 auto $indent-m;
        }
    }

    @media (max-width: $screen-xs) {
        height: auto;
        padding: $indent-l $indent-base;

        .card--simple__image {
            height: 13.4rem;
            margin: 0 auto;
        }

        .form {
            width: auto;
            max-width: $card-simple-item-max-width;
            text-align: left;
        }

        .alert--box--error {
            max-width: $card-simple-item-max-width;
        }

        .button--alt {
            max-width: $card-simple-item-max-width;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media (max-width: $screen-xxs) {
        .button--alt,
        .button--cta {
            max-width: initial;
        }

        .text-center {
            .button--cta {
                width: 100%;
            }
        }
    }
}
